/*eslint-disable*/
import axios from 'axios'
import { API_BASE_URL } from './credentials';
const axiosInstance = (token = null) => {
    const instance = axios.create()

    instance.defaults.headers.post['Content-Type'] = 'application/json'
    instance.defaults.headers['Accept'] = 'application/json'

    instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
    instance.defaults.timeout = 15000
    instance.defaults.baseURL = API_BASE_URL

    if (!!token) {
        instance.defaults.headers.common['Authorization'] = token
    } else {
        const authData = JSON.parse(localStorage.getItem('auth'))
        const token = authData?.accessToken ?? ''
        // consoleLog(token, 'Token')
        instance.defaults.headers.common['Authorization'] = token
    }
    instance.interceptors.request.use(
        function (config) {
            return config
        },
        function (error) {
            return Promise.reject(error)
        }
    )

    // Add a response interceptor
    instance.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            const responseObject = {
                status: response.status,
                data: response.data
            }
            return responseObject
        },
        function (error) {
            return Promise.reject(error)
        }
    )
    return instance
}
export default axiosInstance
