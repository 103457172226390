import {IMAGES} from "../assets/images/images";
import {IThumbnail} from "../helpers/types/types";

const thumbnails: IThumbnail[] = [
    {
        src: IMAGES.Scene1,
        name: "Scene 1",
        videoUrl: 'https://vidvocal.s3.amazonaws.com/upload/60a3643103a2d60df968a64b/60a3643103a2d60df968a64b_sample_one-fourth.mp4#t=10&autoplay=false',
        inputImageSrc: IMAGES.Scene1Input
    },
    {
        src: IMAGES.Scene2,
        name: "Scene 2",
        videoUrl: 'https://vidvocal.s3.amazonaws.com/upload/60a3643103a2d60df968a64b/60a3643103a2d60df968a64b_sample_one-fourth.mp4#t=15&autoplay=false',
        inputImageSrc: IMAGES.Scene2Input
    },
    {
        src: IMAGES.Scene3,
        name: "Scene 3",
        videoUrl: 'https://vidvocal.s3.amazonaws.com/upload/60a3643103a2d60df968a64b/60a3643103a2d60df968a64b_sample_one-fourth.mp4#t=19&autoplay=false',
        inputImageSrc: IMAGES.Scene3Input
    }
]

const videoDescription = [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sagittis egestas ante, nteger nonsed elit',
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sagittis egestas ante, nteger nonsed elit',
]

const STATE_FINISHED = "finished";

const STEPS = [
    '1 Customise Template',
    '2 Make Personal Videos'
]

const TEMPLATE_DATA_KEY = 'templateData';
const VIDEO_RESULT_DATA_KEY = 'videoResultData';
const CREATE_VIDEO_DATA_KEY = 'createVideoData';
const MAKE_VIDEO_DATA_KEY = 'makeVideoData';

export {
    thumbnails,
    videoDescription,
    STATE_FINISHED,
    STEPS,
    TEMPLATE_DATA_KEY,
    VIDEO_RESULT_DATA_KEY,
    CREATE_VIDEO_DATA_KEY,
    MAKE_VIDEO_DATA_KEY
}