import {FC, useEffect} from "react";
import {useNavigate} from "react-router-dom";

const Callback:FC = ():JSX.Element =>{
    const navigate = useNavigate();
    useEffect(()=>{
        navigate('/create-template/60a3643103a2d60df968a64b')
    },[])
   return (<></>);
}

export default Callback;