import {Spinner} from "react-bootstrap";
import React, {FC, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {ITemplateData} from "../../../helpers/types/types";
import {IButtonDisableCheck} from "../../../helpers/types/types";
import {randomNameGenerator} from "../../../helpers/utility/utils";
import {MakeTemplateReqBody} from "../../../constants/PredefinedConstants";
import {fetchVideoTemplate, makeVideo, pngSequence} from "../../../services/christmas";
import {MAKE_VIDEO_DATA_KEY, STATE_FINISHED, VIDEO_RESULT_DATA_KEY} from "../../../constants/TemplateConstatnts";
import styles from "../CreateTemplate.module.scss";
import axios, {AxiosError} from "axios";

interface ICreateTemplateFooter{
    templateData: ITemplateData;
}

const CreateTemplateFooter:FC<ICreateTemplateFooter> = (props): JSX.Element => {
    const buttonDisableObjectInit = {isApply: false, isNext: false};

    const {templateData} = props;
    const [buttonDisableObject, setButtonDisableObject] = useState<IButtonDisableCheck>(buttonDisableObjectInit);
    const [makeVideoResultData, setMakeVideoResultData] = useState<any>({});
    const [videoResultData, setVideoResultData] = useState<any>({});
    const params = useParams();
    const navigate = useNavigate();

    useEffect(()=>{
        const _makeVideoResult = localStorage.getItem(MAKE_VIDEO_DATA_KEY);
        const _videoResultData = localStorage.getItem(VIDEO_RESULT_DATA_KEY);
        if(_makeVideoResult !== null) setMakeVideoResultData(JSON.parse(_makeVideoResult));
        if(_videoResultData !== null) setVideoResultData(JSON.parse(_videoResultData));
    },[])

    const isApplyDisabled = ():boolean =>{
        return templateData.scene1.trim().length === 0 || templateData.scene2.trim().length === 0 ||
            templateData.scene3.trim().length === 0 || templateData.logoUrl.trim().length === 0;
    }

    const onApply = async () => {
        let tempData = {...templateData};
        let templateReqData = {...MakeTemplateReqBody};
        templateReqData.assets[0][0].src = tempData.scene3;
        templateReqData.assets[0][0].value = tempData.scene3;
        templateReqData.assets[0][1].src = tempData.scene1;
        templateReqData.assets[0][1].value = tempData.scene1;
        templateReqData.assets[0][2].src = tempData.scene2;
        templateReqData.assets[0][2].value = tempData.scene2;
        templateReqData.assets[0][3].src = tempData.logoUrl;
        templateReqData.assets[0][3].value = tempData.logoUrl;
        templateReqData.jobName = randomNameGenerator(8, "website-christmas-");
        try{
            if(!params.id) return;
            const res = await makeVideo({templateId: params.id, data: templateReqData});
            setMakeVideoResultData({...makeVideoResultData, ...res});
            localStorage.setItem(MAKE_VIDEO_DATA_KEY, JSON.stringify({...res}));
            try{
                setButtonDisableObject({...buttonDisableObject, isApply: true});
                const videoRes = await fetchVideoTemplate(res.data[0][1]);
                setVideoResultData({...videoResultData,...videoRes});
                localStorage.setItem(VIDEO_RESULT_DATA_KEY, JSON.stringify({...videoRes}));
                let interval = setInterval(async ()=>{
                    const videoRes = await fetchVideoTemplate(res.data[0][1]);
                    setVideoResultData({...videoResultData,...videoRes});
                    localStorage.setItem(VIDEO_RESULT_DATA_KEY, JSON.stringify({...videoRes}));
                    if(videoRes.data.state === STATE_FINISHED){
                        setButtonDisableObject({...buttonDisableObject, isApply: false});
                        clearInterval(interval);
                    }
                },60000)

            } catch (error){
                setButtonDisableObject({...buttonDisableObject, isApply: false});
                console.error(error);
            }
        } catch (error){
            console.error(error)
        }
    }

    const onNextClick = async () => {
        try{
            setButtonDisableObject({...buttonDisableObject, isNext: true});
            await pngSequence(videoResultData.data._id);
            try{
                console.log(makeVideoResultData.data[0][1])
                const videoRes = await fetchVideoTemplate(makeVideoResultData.data[0][1]);
                setVideoResultData({...videoResultData,...videoRes});
                localStorage.setItem(VIDEO_RESULT_DATA_KEY, JSON.stringify({...videoRes}));
                let interval = setInterval(async ()=>{
                    const videoRes = await fetchVideoTemplate(makeVideoResultData.data[0][1]);
                    setVideoResultData({...videoResultData,...videoRes});
                    localStorage.setItem(VIDEO_RESULT_DATA_KEY, JSON.stringify({...videoRes}));
                    if(videoRes.data.pngSequenceState === STATE_FINISHED){
                        setButtonDisableObject({...buttonDisableObject, isNext: false});
                        clearInterval(interval);
                        navigate(`/create-video/${videoResultData.data._id}`);
                    }
                },60000)
            } catch (error){
                setButtonDisableObject({...buttonDisableObject, isNext: false});
                console.error(error);
            }
        } catch (error){
            console.error(error);
            setButtonDisableObject({...buttonDisableObject, isNext: false});
        }
    }

    return (
        <div className={`d-flex justify-content-end pd-y-20 pd-x-20 ${styles.templateAction}`}>
            <button type={`button`} className={`btn btn-white rounded-pill btn-min128 position-relative`}
                    onClick={onApply} disabled={isApplyDisabled() || buttonDisableObject.isApply}>
                Apply
                {buttonDisableObject.isApply && <span className={`btn-spinner`}><Spinner animation="border" /></span>}
            </button>
            <button type={`button`}
                    className={`btn btn-danger rounded-pill btn-min128 mg-l-10 position-relative`}
                    disabled={videoResultData?.data?.state !== STATE_FINISHED || buttonDisableObject.isNext} onClick={onNextClick}>
                Next
                {buttonDisableObject.isNext && <span className={`btn-spinner`}><Spinner animation="border" /></span>}
            </button>
        </div>
    )
}

export default CreateTemplateFooter;