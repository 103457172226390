import React, {FC, useState} from "react";
import {useCopyToClipboard} from "usehooks-ts";
import {Toast, ToastContainer} from "react-bootstrap";

import Share from "../../../components/ui/share/Share";

interface IFinalVideoFooter{
    videoPlayUrl: string
}
const FinalVideoFooter: FC<IFinalVideoFooter> = (props): JSX.Element => {
    const {videoPlayUrl} = props;
    const [showShareModal,setShowShareModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [, copy] = useCopyToClipboard();

    const onCopyCLick = ()=>{
        copy(videoPlayUrl).then();
        setShowToast(true);
    }
    return (
        <div className="panel-footer">
            <div className="d-flex align-items-center">
                <div className={`copy-link-wrap`}>
                    <div className={`btn-link-wrap btn-rounded d-flex align-items-center position-relative`}>
                        <p className={`mb-0 link-text text-truncate`}>{videoPlayUrl || 'No link found'}</p>
                        <button className={'btn btn-lg btn-copy'} disabled={!videoPlayUrl} onClick={onCopyCLick}>
                            Copy Link
                        </button>
                        <ToastContainer position="bottom-end">
                            <Toast
                                className="default-toast"
                                onClose={() => setShowToast(false)}
                                show={showToast}
                                delay={1000}
                                autohide
                            >
                                <Toast.Body>Link copied to clipboard</Toast.Body>
                            </Toast>
                        </ToastContainer>
                    </div>
                </div>
                <div className={'share-btn-wrap text-end'}>
                    <button type="button" className="btn-rounded btn btn-danger btn-lg"
                            onClick={()=>setShowShareModal(true)} disabled={!videoPlayUrl}>
                        <i className="icon-share me-2"></i>
                        Share
                    </button>
                    {showShareModal && <Share url={videoPlayUrl} show={showShareModal} setShow={setShowShareModal}/>}
                </div>
            </div>
        </div>
    )
}

export default FinalVideoFooter;