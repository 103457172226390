import {FC} from "react";
import {Modal} from "react-bootstrap";
import {IHowItWorksModal} from "../../../helpers/types/types";
import styles from './HowItWorksModal.module.scss';
import {IMAGES} from "../../../assets/images/images";

const HowItWorksModal: FC<IHowItWorksModal> = (props): JSX.Element =>{
    const {show,setShow} = props;
    return(
        <Modal
            size="xl"
            show={show}
            onHide={() => setShow(false)}
            aria-labelledby="example-modal-sizes-title-sm"
            centered={true}
        >
            <Modal.Header closeButton>
                <Modal.Title className={`font-size-`}>
                    How It Works
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={`pd-y-80 pd-x-0 ${styles.modalBg}`}>
                <div className={` pd-x-30`}>
                    <section className={'modal-section mg-b-60'}>
                        <div className="row">
                            <div className="col-lg-6">
                                <img src={IMAGES.VideoImg1} alt="video1"/>
                            </div>
                            <div className="col-lg-6">
                                <strong className={`${styles.numberCircle} mg-b-30`}>1</strong>
                                <h2 className={`heading1`}>Lorem ipsum dolar</h2>
                                <p className={'font-size-20'}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>
                    </section>
                    <section className={'modal-section mg-b-60'}>
                        <div className="row">
                            <div className="col-lg-6 order-md-1">
                                <img src={IMAGES.VideoImg2} alt="video1"/>
                            </div>
                            <div className="col-lg-6">
                                <strong className={`${styles.numberCircle} mg-b-30`}>2</strong>
                                <h2 className={`heading1`}>Lorem ipsum dolar</h2>
                                <p className={'font-size-20'}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>
                    </section>
                    <section className={'modal-section mg-b-60'}>
                        <div className="row">
                            <div className="col-lg-6">
                                <img src={IMAGES.VideoImg3} alt="video1"/>
                            </div>
                            <div className="col-lg-6">
                                <strong className={`${styles.numberCircle} mg-b-30`}>3</strong>
                                <h2 className={`heading1`}>Lorem ipsum dolar</h2>
                                <p className={'font-size-20'}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>
                    </section>
                    <section className={'modal-section mg-b-60'}>
                        <div className="row">
                            <div className="col-lg-6 order-md-1">
                                <img src={IMAGES.VideoImg4} alt="video1"/>
                            </div>
                            <div className="col-lg-6">
                                <strong className={`${styles.numberCircle} mg-b-30`}>4</strong>
                                <h2 className={`heading1`}>Lorem ipsum dolar</h2>
                                <p className={'font-size-20'}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>
                    </section>
                    <section className={'modal-section'}>
                        <div className="row">
                            <div className="col-lg-6">
                                <img src={IMAGES.VideoImg5} alt="video1"/>
                            </div>
                            <div className="col-lg-6">
                                <strong className={`${styles.numberCircle} mg-b-30`}>5</strong>
                                <h2 className={`heading1`}>Lorem ipsum dolar</h2>
                                <p className={'font-size-20'}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>
                    </section>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default HowItWorksModal;