import {FC} from "react";
import {Modal} from "react-bootstrap";
import {
    EmailShareButton,
    FacebookShareButton,
    LineShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    WhatsappShareButton,

} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    LineIcon,
    LinkedinIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    WhatsappIcon,
} from "react-share";
import {IShareModal} from "../../../helpers/types/types";
import styles from "./Share.module.scss";

const Share: FC<IShareModal> = (props)=> {
    const {show,setShow,url} = props;
    const size = 36;
    return(
        <Modal
            size="sm"
            show={show}
            onHide={() => setShow(false)}
            aria-labelledby="example-modal-sizes-title-sm"
            centered={true}
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm">
                    Share
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul className={`list-unstyled list-group flex-wrap ${styles.shareList}`}>
                    <li>
                        <EmailShareButton url={url}>
                            <EmailIcon size={size} round={true}/>
                        </EmailShareButton>
                    </li>
                    <li>
                        <FacebookShareButton url={url}>
                            <FacebookIcon size={size} round={true}/>
                        </FacebookShareButton>
                    </li>
                    <li>
                        <WhatsappShareButton url={url}>
                            <WhatsappIcon size={size} round={true}/>
                        </WhatsappShareButton>
                    </li>
                    <li>
                        <LinkedinShareButton url={url}>
                            <LinkedinIcon size={size} round={true}/>
                        </LinkedinShareButton>
                    </li>
                    <li>
                        <TelegramShareButton url={url}>
                            <TelegramIcon size={size} round={true}/>
                        </TelegramShareButton>
                    </li>
                    <li>
                        <TwitterShareButton url={url}>
                            <TwitterIcon size={size} round={true}/>
                        </TwitterShareButton>
                    </li>
                    <li>
                        <TumblrShareButton url={url}>
                            <TumblrIcon size={size} round={true}/>
                        </TumblrShareButton>
                    </li>
                    <li>
                        <RedditShareButton url={url}>
                            <RedditIcon size={size} round={true}/>
                        </RedditShareButton>
                    </li>
                    <li>
                        <LineShareButton url={url}>
                            <LineIcon size={size} round={true}/>
                        </LineShareButton>
                    </li>
                    <li>
                        <ViberShareButton url={url}>
                            <ViberIcon size={size} round={true}/>
                        </ViberShareButton>
                    </li>
                </ul>
            </Modal.Body>
        </Modal>
    )
}

export default Share;