import React, {FC} from "react";

interface ICreateVideoTop {
    isSingleVideo: boolean;
    setIsSingleVideo: Function;
}

const CreateVideoTop: FC<ICreateVideoTop> = (props): JSX.Element => {
    const {isSingleVideo, setIsSingleVideo} = props;
    return(
        <ul className={`list-unstyled custom-tabs mb-0`}>
            <li>
                <button type={"button"} className={`btn btn-tab ${isSingleVideo && 'active'}`}
                        onClick={()=>setIsSingleVideo(true)}>
                    Single Video
                </button>
            </li>
            <li>
                <button type={"button"} className={`btn btn-tab ${!isSingleVideo && 'active'}`}
                        onClick={()=>setIsSingleVideo(false)}>
                    Multiple Video
                </button>
            </li>
        </ul>
    )
}

export default CreateVideoTop;