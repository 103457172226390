import {FC} from "react";

const PageNotFound: FC = ()=>{
    return(
        <>
            <div className="vh-100 vw-100-100 d-flex justify-content-center align-items-center flex-column">
                <h1>404 Error</h1>
                <p>Sorry, Page not found</p>
            </div>
        </>
    )
}

export  default PageNotFound;