import Logo from './logo.svg';
import Computer from './computer.svg';
import Christmas from './christmas.svg';
import PlayIcon from './play-icon.svg';
import Scene1 from './scene1.svg';
import Scene2 from './scene2.svg';
import Scene3 from './scene3.svg';
import Scene1Input from './schene1-input.svg';
import Scene2Input from './schene2-input.svg';
import Scene3Input from './schene3-input.svg';
import VideoImg1 from './video-img1.svg';
import VideoImg2 from './video-img2.svg';
import VideoImg3 from './video-img3.svg';
import VideoImg4 from './video-img4.svg';
import VideoImg5 from './video-img5.svg';


export const IMAGES = {
    Logo,
    Computer,
    Christmas,
    PlayIcon,
    Scene1,
    Scene2,
    Scene3,
    Scene1Input,
    Scene2Input,
    Scene3Input,
    VideoImg1,
    VideoImg2,
    VideoImg3,
    VideoImg4,
    VideoImg5
}