import {FC} from "react";

import styles from "../CreateTemplate.module.scss";

const CheckScene:FC = (): JSX.Element => {
    return (
        <span className={`${styles.iconCheck} d-flex align-items-center justify-content-center`}>
                <i className={`icon-check`}></i>
            </span>
    )
}

export default CheckScene;
