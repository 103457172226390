import {useWindowSize} from "usehooks-ts";
import React, {FC, useEffect, useRef, useState} from "react";

import {getVideoHeight} from "../../../helpers/utility/utils";
import {IMAGES} from "../../../assets/images/images";
import styles from "./Video.module.scss";

interface IVideo{
    src: string,
    classVideoBox?: string
}

const Video: FC<IVideo> = (props):JSX.Element => {
    const { src, classVideoBox } = props;
    const videoRef = useRef<HTMLIFrameElement>(null);
    const [height,setVideoHeight] = useState<number>(0);
    const {width} = useWindowSize();

    useEffect(()=>{
        // console.log(videoRef.current?.offsetWidth)
        setVideoHeight(getVideoHeight(videoRef.current?.offsetWidth));

        return () => {
            setVideoHeight(0)
        }
    },[width])

    return (
        <div className={`${styles.videoBox} ${classVideoBox?.length && classVideoBox}`}>
            {
                src.trim().length ?
                    <iframe style={{height: height}} ref={videoRef} className={styles.video}
                            src={src}
                            frameBorder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                    </iframe>:
                    <>
                        <img className={`img-fluid ${styles.videoImg}`} src={IMAGES.Christmas} alt="video"/>
                        <button type="button" className={`btn btn-link ${styles.playBtn}`}><img
                            className="img-fluid" src={IMAGES.PlayIcon} alt="play-icon"/></button>
                    </>
            }
        </div>
    )
}

export default Video;