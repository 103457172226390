import {IMakeVideoRealtimeMultipleReqBody} from "../helpers/types/types";

export const MakeVideoRealtimeReqBodyData: any = {
    assets: {
        0: [
            {
                name: "your text",
                variable: true,
                type: "data",
                layer_type: "text",
                composition: "main",
                layerName: "your text",
                layerIndex: 0,
                value: "your text",
                sub_type: "Text",
                id: "your text_0",
                maxLength: 9,
                src: "your text"
            },
            {
                name: "First Name",
                variable: true,
                type: "data",
                layer_type: "text",
                composition: "main",
                layerName: "First Name",
                layerIndex: 1,
                value: "your name",
                sub_type: "Text",
                id: "First Name_1",
                maxLength: 7,
                src: "your name"
            },
            {
                name: "background-audio",
                variable: true,
                type: "audio",
                layer_type: "audio",
                composition: "main",
                layerName: "background-audio",
                layerIndex: 2,
                value: "sample text",
                sub_type: "audio",
                id: "background-audio_2",
                src: null,
                isPersonalizedAudio: false,
                bgAudioUrl: "https://vidvocal.s3.ap-south-1.amazonaws.com/png-sequences-compressed/vidvocal-website/Vidvocal+VO+_+BG.mp3",
                startTime: 0,
                selectedActor: {
                    name: "en-In-Wavenet-B",
                    ssmlGender: "MALE",
                    languageCode: "en-IN"
                }
            },
            {
                name: "Clickable Area",
                variable: true,
                type: "overlay",
                layer_type: "overlay",
                composition: "main",
                layerName: "Clickable Area",
                layerIndex: 3,
                value: "https://vidvocal.com",
                sub_type: "Text",
                id: "Clickable Area_3",
                maxLength: 12,
                src: "https://vidvocal.com"
            }
        ]
    },
    isCustom: true
}

export const MakeVideoRealtimeMultipleReqBody: IMakeVideoRealtimeMultipleReqBody = {
    assets: null,
    isCustom: true,
    csvKeyBinding: {main_0:"Message",main_1:"Name",main_3:"Clickable Area"},
    csvFile: "Name,Message,Clickable Area\r\n",
    startJobIndex: 0
}

export const MakeTemplateReqBody: any = {
    assets: {
        0: [{
            inPoint: 0,
            outPoint: 61.84,
            scene: "Wish Scene#3",
            TimeToExport: "22",
            name: "Website",
            variable: true,
            parent_comp: "main",
            type: "data",
            layer_type: "text",
            composition: "final logo composition",
            layerName: "website",
            layerIndex: 11,
            property: "Text->Source Text",
            value: "www.reallylongwebsiteurl.com",
            sub_type: "Text",
            id: "1621321656000_tuPJN",
            maxLength: 28,
            src: "www.reallylongwebsiteurl.com"
        }, {
            inPoint: 0,
            outPoint: 13.76,
            scene: "Wish Scene#1",
            TimeToExport: "10",
            name: "Wishes",
            variable: true,
            parent_comp: "main",
            type: "data",
            layer_type: "text",
            composition: "your wish here 1",
            layerName: "enter wish#1",
            layerIndex: 1,
            property: "Text->Source Text",
            value: "Merry Xmas",
            sub_type: "Text",
            id: "1621321669154_Nk1hD",
            maxLength: 28,
            src: "Merry Xmas"
        }, {
            inPoint: 0,
            outPoint: 30,
            scene: "Wish Scene#2",
            TimeToExport: "16",
            name: "Wishes",
            variable: true,
            parent_comp: "main",
            type: "data",
            layer_type: "text",
            composition: "your wish here 2",
            layerName: "enter wish#2",
            layerIndex: 1,
            property: "Text->Source Text",
            value: "Wish you Merry Xmas",
            sub_type: "Text",
            id: "1621321669154_v2I3Q",
            maxLength: 28,
            src: "Wish you Merry Xmas"
        }, {
            inPoint: 0,
            outPoint: 52.04,
            scene: "Wish Scene#3",
            TimeToExport: "550",
            name: "Logo",
            variable: true,
            parent_comp: "main",
            type: "image",
            composition: "Logo",
            layerIndex: 2,
            src: "https://vidvocal.s3.ap-south-1.amazonaws.com/video-assets/5cecca94212e9553ecfda8d8/60a3643103a2d60df968a64b/1664882619325-mds.png",
            imageHeight: null,
            imageWidth: null,
            id: "1621321672923_bbRUy",
            value: "https://vidvocal.s3.ap-south-1.amazonaws.com/video-assets/5cecca94212e9553ecfda8d8/60a3643103a2d60df968a64b/1664882619325-mds.png"
        }]
    }, duration: 23, comp: "main", resolution: "half", jobName: "Christmas template"
}
