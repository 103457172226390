import AxiosError from '../exceptions/AxiosError'
import GeneralError from '../exceptions/GeneralError'
import { isDefined } from '../utility/utils'
import axiosInstance from './axiosInstance'

function transformConfig (config, data) {
    let transformedData = data
    if (
        config &&
        isDefined(config, 'headers') &&
        isDefined(config.headers, 'Content-Type') &&
        config.headers['Content-Type'] === 'application/x-www-form-urlencoded'
    ) {
        transformedData = JSON.stringify(data)
    }
    return transformedData
}

/** Axios catch block handler */
const errorHandler = error => {
    // return error
    if (error.response) {
        const { response } = error
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('full response: ', response)

        if (response.status === 500) {
            throw new GeneralError(
                'Opps! There was a problem. Please try again later.',
                500
            )
        }

        if (isDefined(response, 'data')) {
            if (isDefined(response.data, 'statusText')) {
                throw new AxiosError(response.data.statusText, response.status)
            }
            if (
                isDefined(response.data, 'errors') &&
                Array.isArray(response.data.errors)
            ) {
                throw new AxiosError(response.data.errors, response.status)
            }
            if (isDefined(response.data, 'errors')) {
                throw new AxiosError(response.data.errors, response.status)
            } else {
                console.error('Error else')
                throw new AxiosError(response.config, response.status)
            }
        }
        throw new GeneralError(
            'Oops! There was a problem. Please try again later.',
            response.status
        )
    }
    if (error.request) {
        throw new GeneralError('SERVER_DOWN', 99999)
    }
    throw new GeneralError(
        'Oops! There was a problem. Please try again later.',
        400
    )
}

function apiGet ({ apiPath, config = {}, withCredentials = false }) {
    const axiosToUse = axiosInstance()
    const fullUrl = apiPath
    const newConfig = {
        ...config,
        withCredentials
    }

    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosToUse.get(fullUrl, newConfig)
            resolve(response.data)
        } catch (error) {
            reject(error.toString())
        }
    })
}

const apiPost = ({ apiPath, data, config = {}, withCredentials = false }) => {
    const newConfig = {
        ...config,
        withCredentials
    }

    const transformedData = transformConfig(newConfig, data)
    const axiosToUse = axiosInstance()
    const fullUrl = apiPath
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosToUse.post(
                fullUrl,
                transformedData,
                newConfig
            )
            resolve(response.data)
        } catch (error) {
            reject(error.toString())
        }
    })
}

function apiPut ({ apiPath, data, config = {} }) {
    const newConfig = {
        ...config
    }
    const transformedData = transformConfig(newConfig, data)
    const axiosToUse = axiosInstance()
    const fullUrl = apiPath

    return new Promise(async (resolve, reject) => {
        try {
            const response = await axiosToUse.put(fullUrl, transformedData, newConfig)
            resolve(response.data)
        } catch (error) {
            reject(error.toString())
        }
    })
}

export { apiGet, apiPost, apiPut }
