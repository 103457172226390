import {RouteObject,useRoutes} from "react-router-dom";
import {RouterConstants} from "./RouterConstants";
import CreateVideo from "../pages/createVideo/CreateVideo";
import Layout from "../components/ui/layouts/Layout";
import PageNotFound from "../pages/pageNotFound/PageNotFound";
import CreateTemplate from "../pages/CreateTemplate/CreateTemplate";
import Callback from "../pages/callback/Callback";

const RoutesConfig = () => {
    let routes: RouteObject[] = [
        {
            path: RouterConstants.BASE,
            element: <Callback/>
        },
        {
            path: RouterConstants.CREATE_TEMPLATE,
            element: <Layout children={<CreateTemplate/>}></Layout>
        },
        {
            path: RouterConstants.CREATE_VIDEO,
            element: <Layout children={<CreateVideo/>}></Layout>
        },
        {
            path: RouterConstants.PAGE_NOT_FOUND,
            element: <PageNotFound/>
        }
    ]
    let route = useRoutes(routes);
    return route;
}

export default RoutesConfig;