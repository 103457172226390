import {IChristmas} from "../../../helpers/types/types";

export const formatMultiVideoData = (multiVideoData: IChristmas[], csvFile: string) =>{
    multiVideoData.map( videoData => {
        csvFile = `${csvFile}${videoData.name},${videoData.message}\r\n`;
    })
    return csvFile;
}

export const formatMultiVideoResultData = (multiVideoResultData: string) => {
    let newArr = multiVideoResultData.split('\"').join('').split('\n');
    let resultArr = [];
    for (let i = 0; i < newArr.length - 1; i++){
        resultArr[i] = newArr[i + 1].split(',');
    }
    console.log(resultArr)
    return resultArr;
}