import {FC, useState} from "react";
import {IMAGES} from "../../../assets/images/images";
import styles from "./Header.module.scss";
import {IHeader} from "../../../helpers/types/types";
import {STEPS} from "../../../constants/TemplateConstatnts";
import HowItWorksModal from "../HowItWorksModal/HowItWorksModal";
import {useWindowSize} from "usehooks-ts";

const Header: FC<IHeader> = (props) => {
    const {currentStep} = props;
    const [openHowItWorksModal, setOpenHowItWorksModal] = useState(false);
    const {width} = useWindowSize();

    return(
        <header className={styles.header}>
            <div className="container">
                <div className="d-flex justify-content-between align-items-center flex-wrap">
                    <div className={`d-flex align-items-center flex-wrap`}>
                        <div className={`${styles.logo}`}>
                            <img className={'img-fluid'} src={IMAGES.Logo} alt="logo"/>
                        </div>
                        <ul className={`list-unstyled list-group flex-row flex-wrap ${styles.steps}`}>
                            {STEPS.map((step, index)=>{
                                return(
                                    <li key={index} className={`${(currentStep === index) && styles.active} ${(currentStep > index) && styles.done}`}>
                                        <p className={`${styles.stepName} mb-0 text-truncate`}>{ width > 992 ?step: index+1}</p>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <button type="button" className="btn btn-snow btn-sm rounded-pill"
                    onClick={()=> setOpenHowItWorksModal(true)}>
                        How it Works
                    </button>
                    {openHowItWorksModal && <HowItWorksModal show={openHowItWorksModal} setShow={setOpenHowItWorksModal}/>}
                </div>
            </div>
        </header>
    )
}

export default Header;