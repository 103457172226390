import React from 'react';
import {BrowserRouter} from "react-router-dom";
import RoutesConfig from "./routing/RoutesConfig";

function App() {
  return (
    <BrowserRouter>
        <RoutesConfig/>
    </BrowserRouter>
  );
}

export default App;
