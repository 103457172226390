import {apiGet, apiPost} from "../helpers/configs/axiosConfig";
import {MAKE_VIDEO, MAKE_VIDEO_REALTIME, PNG_SEQUENCE, TEMPLATES, VIDEO, VIDEOS} from "../helpers/apis/apis";
import {API_KEY} from "../helpers/configs/credentials";
import {MakeTemplateReqBody, MakeVideoRealtimeReqBodyData} from "../constants/PredefinedConstants";

//fetch make realtime video template by id
export const fetchVideoTemplate = async (templateId: string) => {
    return  await apiGet({apiPath: `${VIDEO}/${templateId}?userApiKey=${API_KEY}`});
}

//create realtime video for given template id
export const makeVideoRealtime = async ({templateId, data}: {templateId: string, data: any}) => {
    return await apiPost({
        apiPath: `${MAKE_VIDEO_REALTIME}/${templateId}?userApiKey=${API_KEY}`,
        data: {...MakeVideoRealtimeReqBodyData,...data}
    })
}

//fetch video templates by id
export const fetchTemplate = async (templateId: string) => {
    return  await apiGet({apiPath: `${TEMPLATES}/${templateId}?userApiKey=${API_KEY}`});
}

//upload image asset
export const uploadAsset = async ({templateId, data}: {templateId: string, data: any}) => {
    return await apiPost({
        apiPath: `${TEMPLATES}/${templateId}/assets?userApiKey=${API_KEY}`,
        data: data,
        config: {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }
    })
}

//create Video Template for given template id
export const makeVideo = async ({templateId, data}: {templateId: string, data: any}) => {
    return await apiPost({
        apiPath: `${MAKE_VIDEO}/${templateId}?userApiKey=${API_KEY}`,
        data: {...MakeTemplateReqBody,...data}
    })
}

//finish template video
export const pngSequence = async (videoId: string) => {
    return  await apiGet({apiPath: `${VIDEO}/${videoId}${PNG_SEQUENCE}?userApiKey=${API_KEY}`});
}

//get multiple videos
export const fetchTemplateVideos = async (parentId: string, limit: number) => {
    return  await apiGet({apiPath: `${VIDEOS}?skip=0&limit=${limit}&parentId=${parentId}&sendCSV=true&userApiKey=${API_KEY}`});
}