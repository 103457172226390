import {useNavigate, useParams} from "react-router-dom";
import {ChangeEvent, FC, useEffect, useState} from "react";

import {
    TEMPLATE_DATA_KEY,
    thumbnails,
    videoDescription
} from "../../constants/TemplateConstatnts";
import Video from "../../components/common/video/Video";
import TemplateTrack from "./TemplateTrack/TemplateTrack";
import Header from "../../components/common/header/Header";
import {RouterConstants} from "../../routing/RouterConstants";
import {fetchTemplate, uploadAsset} from "../../services/christmas";
import {ISelectedScene, ITemplateData} from "../../helpers/types/types";
import CreateTemplateFooter from "./CreateTemplateFooter/CreateTemplateFooter";
import styles from './CreateTemplate.module.scss';

const CreateTemplate:FC = (): JSX.Element =>{
    const selectedSceneInit = {...thumbnails[0], selectedIndex: 0}
    const templateDataInit = {scene1: '',scene2: '', scene3: '', logoUrl: ''};

    const [selectedScene, setSelectedScene] = useState<ISelectedScene>(selectedSceneInit);
    const [templateData, setTemplateData] = useState<ITemplateData>(templateDataInit);
    const params = useParams();
    const navigate = useNavigate();

    const handleSelection = (index: number) => {
        setSelectedScene({...thumbnails[index], selectedIndex: index});
    }

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setTemplateData({...templateData, [name]: value});
        localStorage.setItem(TEMPLATE_DATA_KEY, JSON.stringify({...templateData, [name]: value}));
    }

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
       if(!e.target.files || e.target.files.length === 0){
           return
       }
       try{
           if(!params.id) return;
           const formData = new FormData();
           console.log(formData, FormData)
           formData.append('template', e.target.files[0])
           const res = await uploadAsset({templateId: params.id, data: formData});
           setTemplateData({...templateData, logoUrl: res.data})
           localStorage.setItem(TEMPLATE_DATA_KEY, JSON.stringify({...templateData, logoUrl: res.data}));
       } catch (error){
           console.error(error)
       }
    }


    const getTemplate = async ()=>{
        try {
            if(!params.id) return;
            await fetchTemplate(params.id);
        } catch (error){
            console.error(error);
            navigate(RouterConstants.PAGE_NOT_FOUND);
        }
    }

    useEffect(()=>{
        getTemplate().then();
        const _templateData = localStorage.getItem(TEMPLATE_DATA_KEY);
        if(_templateData !== null) setTemplateData(JSON.parse(_templateData));
    },[])

    return(
        <>
            <Header currentStep={0}/>
            <div className={`page-box`}>
                <div className="container">
                    <div className={`content-box`}>
                        <div className={`row g-0`}>
                            <div className="col-lg-8">
                                <div className={styles.templateLeftPanel}>
                                    <div className={`pd-y-20 pd-x-20`}>
                                        <div className={styles.templateContent}>
                                            <h3 className={`heading2`}>{selectedScene.name}</h3>
                                            <div className={styles.templatePreview}>
                                                <img className={`img-fluid`} src={selectedScene.src} alt={selectedScene.name}/>
                                                {
                                                    selectedScene.selectedIndex ===0 &&
                                                    <div className={styles.sceneInputWrap}>
                                                        <img className={`img-fluid`} src={selectedScene.inputImageSrc} alt="input"/>
                                                        <input className={`form-control ${styles.input}`}
                                                               type="text"
                                                               name="scene1"
                                                               placeholder="Enter your wish..."
                                                               value={templateData.scene1}
                                                               onChange={handleInputChange}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    selectedScene.selectedIndex === 1 &&
                                                    <div className={styles.sceneInputWrap}>
                                                        <img className={`img-fluid`} src={selectedScene.inputImageSrc} alt="input"/>
                                                        <input className={`form-control ${styles.input} ${styles.inputScene2}`}
                                                               type="text"
                                                               name="scene2"
                                                               placeholder="Enter your wish..."
                                                               value={templateData.scene2}
                                                               onChange={handleInputChange}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    selectedScene.selectedIndex === 2 &&
                                                    <>
                                                        <div className={`${templateData.logoUrl.trim().length ? styles.logoUploadWrapper : styles.logoUploadText} d-flex align-items-center justify-content-center`}>
                                                            {
                                                                templateData.logoUrl.trim().length ?
                                                                    <img className={`img-fluid`} src={templateData.logoUrl} alt="logo"/>
                                                                    :
                                                                    <p className={`mb-0 pd-y-30 text-center ${styles.uploadText}`}>Click to upload
                                                                        <br/>logo here...</p>
                                                            }
                                                            <input type="file" className={`hidden-input`} name="logoUpload" accept=".jpeg, .jpg, .png, .svg" onChange={handleFileChange}/>
                                                        </div>
                                                        <div className={styles.sceneInputWrap}>
                                                            <img className={`img-fluid`} src={selectedScene.inputImageSrc} alt="input"/>
                                                            <input className={`form-control ${styles.input} ${styles.inputScene3}`}
                                                                   type="url"
                                                                   name="scene3"
                                                                   placeholder="Enter your website name..."
                                                                   value={templateData.scene3}
                                                                   onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <TemplateTrack selectedScene={selectedScene} handleSelection={handleSelection} templateData={templateData}></TemplateTrack>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className={`pd-t-20 pd-b-60 pd-x-20 ${styles.templateRightPanel} position-relative`}>
                                    <div className={`mg-b-45`}>
                                        <h3 className={`heading2`}>Render Video</h3>
                                        {thumbnails.map((e, i)=>{
                                            return(
                                                selectedScene.selectedIndex ===i && <Video key={i} src={e.videoUrl}/>
                                            )
                                        })}
                                    </div>
                                    <div>
                                        <h3 className={`heading2`}>Lorem ipsum dolor sit amet</h3>
                                        <ul className={`list-unstyled ${styles.videoDescriptionList}`}>
                                            {videoDescription.map((description, index)=>{
                                                return(
                                                    <li key={index}>
                                                        <i className={`icon-double-chevron ${styles.iconLeft}`}></i>
                                                        {description}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                    <CreateTemplateFooter templateData={templateData}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )}

export default CreateTemplate;