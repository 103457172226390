import {Carousel} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import React, {ChangeEvent, FC, useEffect, useState} from "react";

import Video from "../../components/common/video/Video";
import Header from "../../components/common/header/Header";
import {fetchVideoTemplate} from "../../services/christmas";
import CreateVideoTop from "./CreateVideoTop/CreateVideoTop";
import {RouterConstants} from "../../routing/RouterConstants";
import FinalVideoFooter from "./FinalVideoFooter/FinalVideoFooter";
import {IChristmas, IFinalVideoData} from "../../helpers/types/types";
import CreateVideoFooter from "./CreateVideoFooter/createVideoFooter";
import {VIDEO_PLAY_BASE_URL, VIDEO_PLAYER_BASE_URL} from "../../constants/GlobalConstants";
import {IMAGES} from "../../assets/images/images";
import styles from "./CreateVideo.module.scss"

const CreateVideo: FC = (): JSX.Element => {
    const videoInitialData = [{name: '', message: ''}]
    const finalVideoDataInit = {index: 0,selectedVideoId: '', videoIds: []};

    const [isSingleVideo, setIsSingleVideo] = useState(true);
    const [videoData, setVideoData] = useState<IChristmas[]>(videoInitialData);
    const [isGetVideoDisabled, setIsGetVideoDisabled] = useState(true);
    const [finalVideoData, setFinalVideoData] = useState<IFinalVideoData>(finalVideoDataInit);

    const handleSelect = (selectedIndex: number, e: Record<string, unknown> | null) => {
        setFinalVideoData({...finalVideoData, index: selectedIndex, selectedVideoId: finalVideoData.videoIds[selectedIndex]});
    };
    const params = useParams();
    const navigate = useNavigate();

    const onChangeText = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const {name, value} = e.target;
        let data = [...videoData];
        checkGetVideoDisable(data);
        switch (name) {
            case "name": data[index].name = value;
                break
            case "message": data[index].message = value;
                break;
            default: break;
        }
        setVideoData(data);
    };

    const addUser = () => {
        let data = [...videoData];
        data.push({name: '', message: ''})
        setVideoData(data)
    }

    const removeUser = (index: number) => {
        let data = [...videoData];
        data = data.filter(e => e !== data[index]);
        setVideoData(data);
    }

    useEffect(()=>{
        setVideoData(videoInitialData);
        checkGetVideoDisable(videoInitialData);
    },[isSingleVideo]);

    useEffect(()=>{
        getVideoTemplate().then();
    },[])

    const getVideoTemplate = async () => {
        try {
            if(!params.id) return;
            await fetchVideoTemplate(params.id);
        } catch (error){
            console.error(error);
            navigate(RouterConstants.PAGE_NOT_FOUND);
        }
    }

    const checkGetVideoDisable = (vData: IChristmas[]) =>{
        vData.map( v=> {
            if(!v.name.trim() || !v.message.trim()){
                setIsGetVideoDisabled(true);
            } else {
                setIsGetVideoDisabled(false)
            }
        })
    }

    return (
        <>
            <Header currentStep={1}/>
            <div className={`page-box`}>
                <div className="container">
                    <div className={`content-box`}>
                        <div className={`row g-0`}>
                            <div className="col-lg-4">
                                <form onSubmit={e => e.preventDefault()} className={`pd-b-60 ${styles.templateLeftPanel} position-relative`}>
                                    <CreateVideoTop isSingleVideo={isSingleVideo} setIsSingleVideo={setIsSingleVideo}/>
                                    <div className={`pd-t-24 pd-b-80`}>
                                        <ul className={`list-unstyled mb-0 ${styles.inputList}`}>
                                            {
                                                isSingleVideo ?
                                                    <li>
                                                        <>
                                                            <input className="form-control mg-b-10" type="text"
                                                                   placeholder="Enter your name" name="name"
                                                                   value={videoData[0].name}
                                                                   onChange={(e) => onChangeText(e, 0)}
                                                                   required/>
                                                            <input className="form-control" type="text"
                                                                   placeholder="Enter your wish here..." name="message"
                                                                   value={videoData[0].message}
                                                                   onChange={(e) => onChangeText(e, 0)}
                                                                   required/>
                                                        </>
                                                    </li>
                                                    :
                                                    <>
                                                        {videoData.map((data, index) => {
                                                            return (
                                                                <li key={index}>
                                                                    <div className={styles.multipleWrap}>
                                                                        <span className={styles.counter}>{index + 1}.</span>
                                                                        <input className="form-control mg-b-10" type="text"
                                                                               placeholder="Enter your name" name="name"
                                                                               value={data.name}
                                                                               onChange={(e) => onChangeText(e, index)}
                                                                               required/>
                                                                        <input className="form-control" type="text"
                                                                               placeholder="Enter your wish here..."
                                                                               name="message"
                                                                               value={data.message}
                                                                               onChange={(e) => onChangeText(e, index)}
                                                                               required/>
                                                                        {index === videoData.length - 1 ?
                                                                            <button type="button" onClick={addUser}
                                                                                    className={`btn btn-link text-danger ${styles.btnRight}`}>
                                                                                <i className={`icon-plus ${styles.btnAdd}`}></i>
                                                                            </button>
                                                                            :
                                                                            <button type="button"
                                                                                    onClick={() => removeUser(index)}
                                                                                    className={`btn btn-link ${styles.btnRight} ${styles.btnTrash}`}>
                                                                                <i className="icon-trash"></i></button>
                                                                        }
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                    </>
                                            }
                                        </ul>
                                    </div>
                                    <CreateVideoFooter isSingleVideo={isSingleVideo} isGetVideoDisabled={isGetVideoDisabled} videoData={videoData}
                                                       finalVideoData={finalVideoData} setFinalVideoData={setFinalVideoData}/>
                                </form>
                            </div>
                            <div className="col-lg-8">
                                <div className={`${styles.templateRightPanel} pd-t-20 pd-b-90`}>
                                    <div className={`pd-x-20 pd-b-20`}>
                                        <h3 className={`heading2`}>Final Video</h3>
                                        <div>
                                            <div className={`video-box`}>
                                                <div className={`video-wrapper`}>
                                                    {
                                                        finalVideoData.selectedVideoId?.length ?
                                                            <Video src={`${VIDEO_PLAYER_BASE_URL}?id=${finalVideoData.selectedVideoId}&showDownloadButton=false`} classVideoBox={`max-width-100`}/>
                                                            :
                                                            <>
                                                                <img className={`img-fluid ${styles.videoImg}`} src={IMAGES.Christmas} alt="video"/>
                                                                <button type="button" className={`btn btn-link ${styles.playBtn}`}><img
                                                                    className="img-fluid" src={IMAGES.PlayIcon} alt="play-icon"/></button>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            finalVideoData.videoIds?.length>1 &&
                                            <div className={'custom-carousel video-right-panel mg-t-20'}>
                                                <Carousel interval={null} wrap={false} variant={"dark"} activeIndex={finalVideoData.index} onSelect={handleSelect}>
                                                    {
                                                        finalVideoData.videoIds.map((videoId, index)=>{
                                                            return(
                                                                <Carousel.Item></Carousel.Item>
                                                            )
                                                        })
                                                    }
                                                </Carousel>
                                            </div>
                                        }
                                    </div>
                                    <FinalVideoFooter videoPlayUrl={ finalVideoData.selectedVideoId?.length ? `${VIDEO_PLAY_BASE_URL}/${finalVideoData.selectedVideoId}` : ''}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateVideo;