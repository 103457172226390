import React, {FC} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {MakeVideoRealtimeMultipleReqBody, MakeVideoRealtimeReqBodyData} from "../../../constants/PredefinedConstants";
import {fetchTemplateVideos, makeVideoRealtime} from "../../../services/christmas";
import {IChristmas, IFinalVideoData, IMakeVideoRealtimeMultipleReqBody} from "../../../helpers/types/types";
import {formatMultiVideoData, formatMultiVideoResultData} from "../utility/createVideoUtils";

interface iCreateVideoFooter{
    isSingleVideo: boolean,
    isGetVideoDisabled: boolean,
    videoData: IChristmas[],
    finalVideoData: IFinalVideoData,
    setFinalVideoData: Function
}

const CreateVideoFooter: FC<iCreateVideoFooter> = (props): JSX.Element =>{
    const  {isSingleVideo, isGetVideoDisabled, videoData, finalVideoData, setFinalVideoData}= props;
    const params = useParams();
    const navigate = useNavigate();

    const onSubmit = async () => {
        let vidData = [...videoData];
        if(isSingleVideo){
            let reqData = {...MakeVideoRealtimeReqBodyData};
            reqData.assets[0][0].src = vidData[0].message;
            reqData.assets[0][0].value = vidData[0].message;
            reqData.assets[0][1].src = vidData[0].name;
            reqData.assets[0][1].value = vidData[0].name;
            try {
                if(!params.id) return;
                const res = await makeVideoRealtime({templateId: params.id, data: reqData});
                let arr: Array<string> = [];
                arr.push(res.data[0][1])
                setFinalVideoData({...finalVideoData,selectedVideoId: arr[0], videoIds: arr});
            } catch (error){
                console.error(error);
            }
        } else{
            let reqData: IMakeVideoRealtimeMultipleReqBody = {...MakeVideoRealtimeMultipleReqBody};
            reqData.csvFile = formatMultiVideoData(vidData, reqData.csvFile);
            try {
                if(!params.id) return;
                const makeVideosRes = await makeVideoRealtime({templateId: params.id, data: reqData});
                console.log("make multi video Res", makeVideosRes)
                try{
                    const res = await fetchTemplateVideos(makeVideosRes.data._id, videoData.length);
                    let resultData = formatMultiVideoResultData(res.data);
                    let arr: Array<string> = [];
                    for(let i = 0; i< resultData.length; i++){
                        arr.push(resultData[i][0]);
                    }
                    setFinalVideoData({...finalVideoData,selectedVideoId: arr[0], videoIds: arr});
                    console.log("get multi videos", res);
                } catch (error){
                    console.error(error);
                }
            } catch (error){
                console.error(error);
            }
        }
    }

    return (
        <div className="panel-footer text-end">
            <button type="button" className="btn btn-rounded btn-lg btn-white mg-r-10" onClick={()=> navigate(-1)}>
                Back
            </button>
            <button type="submit" className="btn btn-rounded btn-lg btn-danger"
                    onClick={onSubmit} disabled={isGetVideoDisabled}>Get Your Video{isSingleVideo ? '' : 's'}</button>
        </div>
    )
}

export default CreateVideoFooter;