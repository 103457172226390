export const isDefined = (
    object: { [key: string]: any },
    property: any = null,
) => {
    if (property === null) {
        return typeof object !== "undefined";
    }

    return (
        typeof object !== "undefined" &&
        object &&
        typeof object[property] !== "undefined"
    );
};

export const getVideoHeight = (width = 0) => {
    return Math.ceil((9/16) * width);
}

export const randomNameGenerator = (num: number, res: string) => {
    for(let i = 0; i < num; i++){
        const random = Math.floor(Math.random() * 27);
        res += String.fromCharCode(97 + random);
    };
    return res;
};