import React, {FC} from "react";

import CheckScene from "../CheckScene/CheckScene";
import {thumbnails} from "../../../constants/TemplateConstatnts";
import {ISelectedScene, ITemplateData} from "../../../helpers/types/types";
import styles from "../CreateTemplate.module.scss";

interface ITemplateTrack{
    selectedScene: ISelectedScene,
    handleSelection: Function,
    templateData: ITemplateData
}

const TemplateTrack: FC<ITemplateTrack> = (props): JSX.Element => {
    const {selectedScene, handleSelection, templateData} = props;
    return (
        <div className={`pd-y-12 pd-x-20 ${styles.templateTrackWrap}`}>
            <div className={styles.templateTrack}>
                <div className="row g-2">
                    {
                        thumbnails.map((thumbnail, index) => {
                            return(
                                <div key={index} className="col-4">
                                    <h4 className={`font-size-sm`}>#{index+1}</h4>
                                    <div className={`${styles.thumbnail} ${index === selectedScene.selectedIndex && styles.active}`}
                                         onClick={()=>handleSelection(index)}>
                                        { (templateData.scene1.trim().length !==0 && index === 0) &&
                                        <CheckScene/>
                                        }
                                        { (templateData.scene2.trim().length !==0 && index === 1) &&
                                        <CheckScene/>
                                        }
                                        { (templateData.scene3.trim().length !==0 && index === 2 && templateData.logoUrl.length !== 0) &&
                                        <CheckScene/>
                                        }
                                        <img className={`img-fluid`} src={thumbnail.src} alt={thumbnail.name}/>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default TemplateTrack;